
            @import "@/assets/sass/vars.portal.scss";
          















































































































































































.calendar-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .calendar-row {
        display: flex;
        flex-wrap: wrap;
    }

    .day-contents {
        font-size: 13px;
        text-align: center;
    }

    .calendar-header {
        font-weight: bold;
        text-align: center;
        background: #f8f9fa;
    }

    .d-day {
        position: absolute;
        left: 4px;
        top: 4px;
        font-size: 14px;
        font-weight: bold;
    }

    .calendar-cell {
        &:hover {
            border: 1px solid $primary;
            background: $primary;
            color: white !important;
            cursor: pointer;
        }
        flex: 1;
        min-width: 14.285%; /* 100% / 7 for equal spacing */
        max-width: 14.285%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ddd;
        background-color: #fff;
        position: relative;
    }

    .header {
        background: $gray-500;
        color: $default;
        font-weight: bold;
    }

    .empty {
        background: #f0f0f0;
        border: none;
    }
}
